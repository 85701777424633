// 个性签名
const sign = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("签名不能为空");
      } else {
        callback("");
      }
    }
  }
];

// 手机号
const phone = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("请输入手机号码");
      } else if (/^[1]([3456789])[0-9]{9}$/.test(value)) {
        callback();
      } else {
        callback("手机号格式错误");
      }
    }
  }
];

// 手机验证码
const phoneCode = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("验证码不能为空");
      } else if (/^[0-9]{4,6}$/.test(value)) {
        callback();
      } else {
        callback("验证码格式错误");
      }
    }
  }
];
// 推荐码
const referrerCode = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback();
      } else if (/^[0-9a-z]{4,6}$/.test(value)) {
        callback();
      } else {
        callback("推荐码格式错误");
      }
    }
  }
];

const money = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("请输入金额");
      } else if (
        /^\d+(\.\d{0,2})?$/.test(value) &&
        parseFloat(value) <= 10000 &&
        parseFloat(value) > 0
      ) {
        callback();
      } else {
        callback("请输入正确的金额，且额度不大于10000，限2位小数");
      }
    }
  }
];

// 用户名
const Name = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("用户名不能为空");
      }else {
        callback("用户名格式错误");
      }
    }
  }
];
// 登录的时候 可以随便输入
const sginPas = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("请输入密码");
      } else if (/^[A-Za-z0-9_-]{6,20}$/.test(value)) {
        oldpas = value;
        callback();
      } else {
        callback("密码格式错误，6-20位密码");
      }
    }
  }
];
let oldpas = "";
// 密码（注册的时候强制使数字格式）
const pas = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("请输入密码");
      } else if (/^[A-Za-z0-9_-]{6,20}$/.test(value)) {
        oldpas = value;
        callback();
      } else {
        callback("密码格式数字组合，6-20位密码");
      }
    }
  }
];

// 第二次密码
const confirmpas = [
  {
    validator: (rule, value, callback) => {
      if (!value) {
        callback("请输入密码");
      } else if (value !== oldpas) {
        callback("密码不一致");
      } else {
        callback();
      }
    }
  }
];

export { sginPas, confirmpas, pas, sign, phone, phoneCode, money, Name ,referrerCode};
